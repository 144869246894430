<template>
  <mobile-screen
    :header="true"
    :footer="false"
    screen-class="icon-app1 outlook-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="outlook-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("outlook", "duration", "duration") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section tiny-top-spacing">
      <li class="clebex-item-section-item duration">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{ displayLabelName("outlook", "duration", "date-time") }}
              </div>
            </span>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item day-picker">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button class="clebex-item-range-cta">
                {{ displayDate(start) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-calendar" width="20" height="20" />
            </li>
            <li class="clebex-item-range to">
              <button class="clebex-item-range-cta">
                {{ displayDate(end) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="clebex-item-section-item time-picker">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button class="clebex-item-range-cta">
                {{ displayTime(start) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-time" width="20" height="20" />
            </li>
            <li class="clebex-item-range to">
              <button class="clebex-item-range-cta">
                {{ displayTime(end) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing duration-wrapper">
      <li v-if="duration" class="clebex-item-section-item duration">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{ displayLabelName("outlook", "duration", "duration") }}
              </div>
            </span>
            <div class="warning-count-wrapper">
              <div class="warning-count-number">
                {{ duration.hours.toString().length === 1 ? "0" : ""
                }}{{ duration.hours }}:{{
                  duration.minutes.toString().length === 1 ? "0" : ""
                }}{{ duration.minutes }} H
              </div>
              <icon icon="#cx-app1-time" width="14" height="14" />
            </div>
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item no-bottom-border time-selection-wrapper"
      >
        <div class="clebex-item-content-wrapper">
          <div class="title">
            {{ displayLabelName("outlook", "duration", "hours") }}
          </div>
          <div class="time-selection">
            <div
              v-for="(hour, i) in 24"
              :key="i"
              @click="setDuration(i)"
              class="time"
              :class="{
                'hours-selected':
                  (customDuration ? customDuration.hours : duration.hours) === i
              }"
              ref="hours"
            >
              {{ (hour - 1).toString().length === 1 ? "0" : "" }}{{ i }}:00
            </div>
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item no-bottom-border time-selection-wrapper"
      >
        <div class="clebex-item-content-wrapper">
          <div class="title">
            {{ displayLabelName("outlook", "duration", "minutes") }}
          </div>
          <div class="time-selection">
            <div
              v-for="(minute, i) in minuteIncrements"
              :key="i"
              @click="setDuration(false, minute)"
              class="time"
              :class="{
                'minutes-selected':
                  (customDuration
                    ? customDuration.minutes
                    : duration.minutes) == minute
              }"
            >
              00:{{ minute.toString().length === 1 ? "0" : "" }}{{ minute }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul
      v-if="globalTimeFormat"
      class="clebex-item-section calendar tiny-top-spacing"
    >
      <li class="clebex-item-section-item overflow-hidden">
        <div class="clebex-item-content-wrapper">
          <pill-menu-tabs
            :nav-items="pillTabs"
            :preselected-tab="preselectedTab"
          >
            <template v-slot:date>
              <select-date-range
                :selectDateFn="selectDate"
                :show-date-picker="showDateTimePicker"
                :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                :selected-dates="[selectedDateFrom, selectedDateTo]"
                :rangeMarked="false"
              />
            </template>
            <template v-slot:time-slot>
              <select-time-picker-range
                :select-hour-fn="selectHour"
                :selected-hours="[selectedHourFrom, selectedHourTo]"
                :rangeMarked="false"
              />
            </template>
          </pill-menu-tabs>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import SelectDateRange from "@/components/global/SelectDateRange";
import SelectTimePickerRange from "@/components/global/SelectTimePickerRange";
import PillMenuTabs from "@/components/global/PillMenuTabs";
import { mapGetters, mapState, mapActions } from "vuex";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";

export default {
  name: "Duration",
  data() {
    return {
      pillTabs: ["date", "time-slot"],
      preselectedTab: "time-slot",
      showDateTimePicker: true,
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedHourFrom: null,
      selectedHourTo: null,
      selectedDateFromParsed: null,
      selectedDateToParsed: null,
      customDuration: null,
      minuteIncrements: [0, 15, 30, 45]
    };
  },
  created() {
    this.setDateTime(DateTime.fromISO(this.start), DateTime.fromISO(this.end));
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("outlook", ["title", "requiredAttendees", "start", "end"]),
    duration() {
      if (this.customDuration) {
        this.customDuration;
      } else if (this.start && this.end) {
        return DateTime.fromISO(this.end)
          .diff(DateTime.fromISO(this.start), ["hours", "minutes"])
          .toObject();
      }
      return null;
    }
  },
  methods: {
    ...mapActions("outlook", ["setStart", "setEnd"]),
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(DateTime.fromISO(date), this.globalDateFormat);
    },
    displayTime(time) {
      if (!time || !this.globalTimeFormat) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].displayDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].displayDate;
      } else {
        this.selectedDateTo = null;
        this.selectedDateToParsed = null;
      }
      if (dates[0] && dates[1]) {
        this.setCustomDate(dates, false);
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
      if (hours[0] && hours[1]) {
        this.setCustomDate(false, hours);
      }
    },
    setDateTime(from, to) {
      this.selectedDateFrom = DateTime.fromISO(from).toFormat("yyyy-dd-MM");
      this.selectedDateTo = DateTime.fromISO(to).toFormat("yyyy-dd-MM");
      this.selectedDateFromParsed = DateTime.fromISO(from).toFormat(
        "d MMMM yyyy"
      );
      this.selectedDateToParsed = DateTime.fromISO(to).toFormat("d MMMM yyyy");
      this.selectedHourFrom = DateTime.fromISO(from).toFormat("HH:mm");
      this.selectedHourTo = DateTime.fromISO(to).toFormat("HH:mm");
      this.$nextTick(() => {
        this.scrollIntoView();
      });
    },
    scrollIntoView() {
      const selectedElementIndex = this.$refs.hours.findIndex(el =>
        el.className.includes("hours-selected")
      );
      if (selectedElementIndex !== undefined) {
        this.$refs.hours[selectedElementIndex].scrollIntoView({
          behavior: "smooth",
          inline: "center"
        });
      }
    },
    setCustomDate(dates, hours) {
      if (!hours) {
        if (dates[0]) {
          this.setStart(
            `${DateTime.fromISO(
              dates[0].eventDate
            ).toISODate()}T${DateTime.fromISO(this.start).toFormat("HH:mm")}`
          );
          this.postDateTime(this.start, "start");
        }
        if (dates[1]) {
          this.setEnd(
            `${DateTime.fromISO(
              dates[1].eventDate
            ).toISODate()}T${DateTime.fromISO(this.end).toFormat("HH:mm")}`
          );
          this.postDateTime(this.end, "end");
        }
        // customDateTimeFilter.date_start = dates[0]
        //   ? `${DateTime.fromISO(
        //       dates[0].eventDate
        //     ).toISODate()}T${DateTime.fromISO(defaultStart).toFormat("HH:mm")}`
        //   : defaultStart;
        // customDateTimeFilter.date_end = dates[1]
        //   ? `${DateTime.fromISO(
        //       dates[1].eventDate
        //     ).toISODate()}T${DateTime.fromISO(defaultEnd).toFormat("HH:mm")}`
        //   : defaultEnd;
      }
      if (!dates) {
        if (hours[0]) {
          this.setStart(
            `${DateTime.fromISO(this.start).toISODate()}T${DateTime.fromISO(
              hours[0]
            ).toFormat("HH:mm")}`
          );
          this.postDateTime(this.start, "start");
        }
        if (hours[1]) {
          this.setEnd(
            `${DateTime.fromISO(this.end).toISODate()}T${DateTime.fromISO(
              hours[1]
            ).toFormat("HH:mm")}`
          );
          this.postDateTime(this.end, "end");
        }
      }
    },
    postDateTime(date, type) {
      parent.postMessage(
        {
          call: type,
          value: DateTime.fromISO(date).toJSDate()
        },
        "*"
      );
    },
    setDuration(hour, minute) {
      let newDate = DateTime.fromISO(DateTime.fromISO(this.start));
      if (hour !== false) {
        newDate = newDate.plus({ hours: hour, minutes: this.duration.minutes });
      } else {
        newDate = newDate.plus({ hours: this.duration.hours, minutes: minute });
      }
      this.setEnd(
        `${newDate.toISODate()}T${DateTime.fromISO(newDate).toFormat("HH:mm")}`
      );
      this.postDateTime(this.end, "end");
    }
  },
  watch: {
    start() {
      this.setDateTime(this.start, this.end);
    },
    end() {
      this.setDateTime(this.start, this.end);
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange,
    SelectTimePickerRange,
    PillMenuTabs
  }
};
</script>
